import { useEffect, useRef, useState } from "react";
import { format, isValid } from "date-fns";
import * as Popover from '@radix-ui/react-popover';
import * as Dialog from '@radix-ui/react-dialog';
import { CalendarIcon, MagnifyingGlassIcon, PersonIcon } from "@radix-ui/react-icons";
import useScreenSize from "../../hooks/useScreenSize";
import styles from "./style.module.css";
import { RoomsPicker } from "../../components/RoomsPicker";
import { WidjetInput } from "../../UI/WidjetInput";
import { useContext } from "react";
import { useStore } from "zustand";
import { getCalendarPrices } from "./api/getCalendarPrices";
import { Calendar } from "../../components/Calendar/Calendar";
import { WidjetContext } from "../../store/Widjet/widjetContext";
import { getModuleIframe } from "../../utils/getModuleIframe";
import classNames from "classnames";

// Поле "Гости"
function TextFieldRooms({ rooms, onMouseDown, active }) {
    const adultsCount = rooms.reduce((sum, current) => +sum + +current.adults, 0);
    const childsCount = rooms.reduce((sum, current) => +sum + +current.childrens.length, 0);

    return (
        <WidjetInput
            icon={<PersonIcon />}
            label="Гости"
            inputProps={{
                value: `${adultsCount} ${adultsCount > 1 ? "взрослых" : "взрослый"}, ${childsCount} ${childsCount <= 1 && childsCount !== 0 ? "ребенок" : "детей"}`,
                placeholder: "Гости",
                readOnly: true,
                type: "text",
            }}
            onMouseDown={onMouseDown}
            id="booking-rooms-field"
            className={classNames(styles.widjetInputRooms, active ? styles.active : "")}
        />
    )
}

// Поле "Дата Выезда"
function TextFieldTo({ value, onMouseDown, active }) {
    return (
        <WidjetInput
            icon={<CalendarIcon />}
            label="Выезд"
            inputProps={{
                value: value,
                placeholder: "Выезд",
                readOnly: true,
                type: "text",
            }}
            onMouseDown={onMouseDown}

            id="booking-to-field"
            className={classNames(styles.widjetInputTo, active ? styles.active : "")}
        />
    );
}

// Поле "Дата Заезда"
function TextFieldFrom({ value, onMouseDown, active }) {
    return (
        <WidjetInput
            icon={<CalendarIcon />}
            label="Заезд"
            inputProps={{
                value: value,
                placeholder: "Заезд",
                readOnly: true,
                type: "text",
            }}
            onMouseDown={onMouseDown}

            id="booking-from-field"
            className={classNames(styles.widjetInputFrom, active ? styles.active : "")}
        />
    );
}

export function Widjet({ setIsModuleOpen, setModuleIframe, setIsFailed }) {
    const store = useContext(WidjetContext);

    const dateFrom = useStore(store, state => state.dateFrom);
    const setDateFrom = useStore(store, state => state.setDateFrom);

    const dateTo = useStore(store, state => state.dateTo);
    const setDateTo = useStore(store, state => state.setDateTo);

    const rooms = useStore(store, state => state.rooms);
    const setRooms = useStore(store, state => state.setRooms);

    const roomsLimits = useStore(store, state => state.roomsLimits);


    const [month, setMonth] = useState(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)));

    const monthFrom = new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
    const [mode, setMode] = useState("from");
    const [range, setRange] = useState({ from: dateFrom, to: dateTo });

    const [prices, setPrices] = useState(new Map());
    const [uploadedMonth, setUploadedMonth] = useState(new Set());

    const [numberOfMonths, setNumberOfMonths] = useState(2);

    useEffect(() => {
        if (range.from !== null && range.to !== null) {
            setDateFrom(range.from);
            setDateTo(range.to);
        }
    }, [range, setDateFrom, setDateTo]);

    function uploadPrices() {
        for (let i = 0; i < numberOfMonths; i++) {
            const key = new Date(new Date(month).setMonth(month.getMonth() + i));

            if (!uploadedMonth.has(format(key, "yyyy-MM-dd"))) {
                const from = new Date(key);
                const to = new Date(new Date(new Date(new Date(key).setMonth(key.getMonth() + 1)).setDate(0)).setHours(0, 0, 0, 0))

                getCalendarPrices(format(from, 'yyyy-MM-dd'), format(to, 'yyyy-MM-dd'))
                    .then(data => {
                        setUploadedMonth(um => new Set(um).add(format(key, "yyyy-MM-dd")));
                        if (!data) {
                            return;
                        }
                        if (!Array.isArray(data)) {
                            data = [data]
                        }
                        data.forEach(item => {
                            setPrices(prices => {
                                return new Map(prices).set(format(new Date(new Date(item.Date).setHours(0, 0, 0, 0)), "yyyy-MM-dd"), item.MinPrice)
                            })
                        });
                    })
                    .catch(() => {
                        setIsFailed(true)
                    })
            }
        }
    }

    useEffect(() => {
        uploadPrices()
        // eslint-disable-next-line
    }, [month, numberOfMonths]);


    function updatePrices() {
        setPrices(new Map());
        setUploadedMonth(new Set());
        uploadPrices();
    }


    const [calendarOpen, setCalendarOpen] = useState(false);

    // Размер экрана
    const size = useScreenSize();

    const [roomsOpen, setRoomsOpen] = useState(false);

    // Отключаем действие по умолчанию при клике на поля (действие по умолчанию - закрытие поповера)
    function preventInteractOutside(e) {
        let ids = ["booking-from-field", "booking-to-field", "booking-rooms-field"];
        if (ids.includes(e.target.id) || e.target.closest(ids.map(item => `#${item}`))) {
            e.preventDefault();
        }
    }

    // Нажатие на инпут выезда
    function mouseDownToFieldHandler() {
        setRange({ from: dateFrom, to: dateTo })
        if (!calendarOpen) {
            // updatePrices();
        }
        setNumberOfMonths(2);
        if (mode === "from") {
            setCalendarOpen(true);
        } else {
            setCalendarOpen(!calendarOpen);
        }
        if (isValid(dateTo)) {
            setMonth(size.width > 768 ? new Date(new Date(new Date(dateTo).setDate(1)).setHours(0, 0, 0, 0)) : monthFrom);
        }
        setMode("to");
    }

    function clickHandler(day) {
        if (mode === "from") {
            setRange({ from: day, to: null });
            setMode("to");
        } else if (mode === "to") {
            if (day.getTime() < range.from?.getTime()) {
                setRange(range => ({ from: day, to: null }))
            } else if (day.getTime() !== range.from?.getTime()) {
                setRange(range => ({ ...range, to: day }))
                setCalendarOpen(false)
            }
        }
    }

    // Нажатие на инпут въезда
    function mouseDownFromFiledHandler() {
        setRange({ from: dateFrom, to: dateTo })
        if (!calendarOpen) {
            // updatePrices();
        }
        setNumberOfMonths(2);
        setCalendarOpen(!calendarOpen);
        if (isValid(dateFrom) && isValid(dateTo)) {
            setMonth(size.width > 768 ? new Date(new Date(new Date(dateFrom).setDate(1)).setHours(0, 0, 0, 0)) : monthFrom);
        }
        setMode("from");
    }


    // Проверяем форму и открываем модуль бронирования - "/module/booking/rooms"

    function sumbitHandler(e) {
        e.preventDefault();

        function validate() {
            // Проверка на незаполненного ребенка
            if (rooms.find(item => item.childrens.filter(children => children === "").length !== 0)) {
                setRoomsOpen(true);
                return false;
            }

            return true;
        }

        if (validate()) {
            setModuleIframe(getModuleIframe(process.env.MODULE_ORIGIN, dateFrom, dateTo, rooms, timeSettings));
            window.history.replaceState({ ...window.history.state, lv_module_open: false }, "", window.location.href);
            window.history.pushState({ ...window.history.state, lv_module_open: true }, "", window.location.href);
            setIsModuleOpen(true);
        }
    }

    const timeSettings = useStore(store, state => state.timeSettings);

    // if (prices.size === 0) {
    //     return <FakeWidjet />
    // }

    return (
        <div className={styles.wrapper}>
            <form className={styles.form} onSubmit={sumbitHandler}>
                {/* calendar */}
                {size.width < 768 ?
                    <>
                        <TextFieldFrom value={format(dateFrom, "dd.MM.yyyy")} onMouseDown={mouseDownFromFiledHandler} active={calendarOpen && mode === "from"} />
                        <TextFieldTo value={format(dateTo, "dd.MM.yyyy")} onMouseDown={mouseDownToFieldHandler} active={calendarOpen && mode === "to"} />
                        <Dialog.Root open={calendarOpen} onOpenChange={state => {
                            setNumberOfMonths(2);
                            setCalendarOpen(state);
                        }}>
                            <Dialog.Portal>
                                <Dialog.Overlay className={styles.dialogOverlay} />
                                <Dialog.Content className={styles.dialogContent}>
                                    <Calendar
                                        month={month}
                                        setMonth={setMonth}
                                        monthFrom={monthFrom}
                                        numberOfMonths={numberOfMonths}
                                        setNumberOfMonths={setNumberOfMonths}
                                        mode={mode}
                                        setMode={setMode}
                                        range={range}
                                        setRange={setRange}
                                        uploadedMonth={uploadedMonth} // потом будет в стейт менеджере
                                        prices={prices}
                                        clickHandler={clickHandler}
                                        description={`Цена указана за самый дешевый доступный номер без дополнительных мест`}
                                        timeSettings={timeSettings}
                                    />
                                </Dialog.Content>
                            </Dialog.Portal>
                        </Dialog.Root>
                    </>
                    :
                    <Popover.Root open={calendarOpen} onOpenChange={state => setCalendarOpen(state)}>
                        <TextFieldFrom value={format(dateFrom, "dd.MM.yyyy")} onMouseDown={mouseDownFromFiledHandler} active={calendarOpen && mode === "from"} />
                        <Popover.Anchor style={{ position: "absolute", left: '50%' }} />
                        <TextFieldTo value={format(dateTo, "dd.MM.yyyy")} onMouseDown={mouseDownToFieldHandler} active={calendarOpen && mode === "to"} />
                        <Popover.Content
                            side="top"
                            onFocusOutside={preventInteractOutside}
                            onInteractOutside={preventInteractOutside}
                            updatePositionStrategy="always"
                        >
                            <div style={{ marginBottom: 16 }}>
                                <Calendar
                                    month={month}
                                    setMonth={setMonth}
                                    monthFrom={monthFrom}
                                    numberOfMonths={numberOfMonths}
                                    setNumberOfMonths={setNumberOfMonths}
                                    mode={mode}
                                    setMode={setMode}
                                    range={range}
                                    setRange={setRange}
                                    uploadedMonth={uploadedMonth} // потом будет в стейт менеджере
                                    prices={prices}
                                    clickHandler={clickHandler}
                                    description={`Цена указана за самый дешевый доступный номер без дополнительных мест`}
                                    timeSettings={timeSettings}
                                />
                            </div>
                        </Popover.Content>
                    </Popover.Root>
                }

                {/* rooms */}
                {
                    size.width < 768 ?
                        <>
                            <TextFieldRooms rooms={rooms} onMouseDown={() => setRoomsOpen(!roomsOpen)} active={roomsOpen} />
                            <Dialog.Root open={roomsOpen} onOpenChange={state => setRoomsOpen(state)}>
                                <Dialog.Portal>
                                    <Dialog.Overlay className={styles.dialogOverlay} />
                                    <Dialog.Content className={styles.dialogContent}>
                                        <RoomsPicker
                                            state={rooms}
                                            setState={setRooms}
                                            limits={roomsLimits}
                                            setOpen={setRoomsOpen}
                                        />
                                    </Dialog.Content>
                                </Dialog.Portal>
                            </Dialog.Root>
                        </>
                        :
                        <Popover.Root open={roomsOpen} onOpenChange={state => setRoomsOpen(state)}>
                            <Popover.Anchor>
                                <TextFieldRooms rooms={rooms} onMouseDown={() => setRoomsOpen(!roomsOpen)} active={roomsOpen} />
                            </Popover.Anchor>
                            <Popover.Content
                                side="top"
                                onFocusOutside={preventInteractOutside}
                                onInteractOutside={preventInteractOutside}
                                updatePositionStrategy="always"
                            >
                                <div style={{ marginBottom: 16 }} >
                                    <RoomsPicker
                                        state={rooms}
                                        setState={setRooms}
                                        limits={roomsLimits}
                                        setOpen={setRoomsOpen}
                                    />
                                </div>
                            </Popover.Content>
                        </Popover.Root>
                }
                <button color="dark" className={styles.submit}><MagnifyingGlassIcon /> Найти</button>
            </form>
        </div>
    );
}