import { CalendarIcon, MagnifyingGlassIcon, PersonIcon } from "@radix-ui/react-icons";
import styles from "./style.module.css";
import { FakeWidjetInput } from "../../UI/WidjetInput/FakeWidjetInput";

// Поле "Гости"
function TextFieldRooms({ onMouseDown }) {
    return (
        <FakeWidjetInput
            icon={<PersonIcon />}
            label="Гости"
            onMouseDown={onMouseDown}
            className={styles.widjetInputRooms}
        />
    )
}

// Поле "Дата Выезда"
function TextFieldTo({ onMouseDown }) {
    return (
        <FakeWidjetInput
            icon={<CalendarIcon />}
            label="Выезд"
            className={styles.widjetInputTo}
        />
    );
}

// Поле "Дата Заезда"
function TextFieldFrom({ onMouseDown }) {
    return (
        <FakeWidjetInput
            icon={<CalendarIcon />}
            label="Заезд"
            className={styles.widjetInputFrom}
        />
    );
}


export function FakeWidjet({ children }) {
    return (
        <div className={styles.wrapper}>
            <form className={styles.form} onSubmit={e => e.preventDefault()}>
                <TextFieldFrom />
                <TextFieldTo />
                <TextFieldRooms />
                <button color="dark" className={styles.submit}><MagnifyingGlassIcon /> Загрузка...  </button>
            </form>
        </div>
    );
}