import { ReloadIcon } from "@radix-ui/react-icons";
import styles from "./style.module.css";
import classNames from "classnames";

export function ErrorWidjet({ callback }) {
    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.form, styles.errorForm)}>
                <div className={styles.errorMessage}>
                    <div className={styles.errorMessageTitle}>Ошибка при загрузке модуля</div>
                    <div className={styles.errorMessageDescription}>Попробуйте перезагрузить модуль нажав на кнопку обновить</div>
                </div>
                <button color="dark" className={styles.submit} style={{ borderRadius: 16 }} onClick={callback}>Обновить <ReloadIcon /></button>
            </div>
        </div>
    )
}